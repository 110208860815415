
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';
import { compose } from 'recompose';
import * as CmsFragment from '@graphql/fragments/cms';
import { Homepage } from '@graphql/types/server';

const data: { homepage: Homepage } = {
    homepage: 
      {
        "hero_title": [
            {
                "type": "heading1",
                "text": "Let's change the future",
                "spans": []
            }
        ],
        "hero_paragraph": [
            {
                "type": "paragraph",
                "text": "Crowdfund and crowdinvest in environmental projects that have a positive impact on climate change. Make yourself carbon positive and make a green return on your investment.",
                "spans": []
            }
        ],
        "cta": "Try now for free",
        "poster": {
            "dimensions": {
                "width": 1588,
                "height": 992
            },
            "alt": null,
            "copyright": null,
            "url": "https://images.prismic.io/carbonex/6c59f0f0-d0b0-4faa-8523-42549efbc03a_poster.png?auto=compress,format"
        },
        "laptop": {
            "dimensions": {
                "width": 4096,
                "height": 2335
            },
            "alt": "Laptop Frame",
            "copyright": null,
            "url": "https://images.prismic.io/carbonex/12f947f4-ddf5-4f66-9929-f2a7d11ab2f8_laptop.png?auto=compress,format"
        },
        "shadow": {
            "dimensions": {
                "width": 4096,
                "height": 589
            },
            "alt": "Laptop Shadow",
            "copyright": null,
            "url": "https://images.prismic.io/carbonex/2d14b840-2d13-4cec-b2f9-a6930329c2b7_shadow.png?auto=compress,format"
        },
        "video": {
            "__typename": "_FileLink",
            "_linkType": "Link.file",
            "name": "CX-Macbook-Screen-bg.mp4",
            "url": "https://prismic-io.s3.amazonaws.com/carbonex/95fbff04-eb49-4d98-b918-dfd40fa40b49_CX-Macbook-Screen-bg.mp4",
            "size": 8085910
        },
        "body": [
            {
                "type": "carbon_positive",
                "label": null,
                "primary": {
                    "title": [
                        {
                            "type": "heading2",
                            "text": "Everyone can be carbon positive",
                            "spans": []
                        }
                    ],
                    "message": [
                        {
                            "type": "paragraph",
                            "text": "We can now act together to slow climate change. Each project on Carbonex helps limit the carbon in our atmosphere. When you fund or invest on the platform, you'll become a carbon positive person - making a truly positive impact on the world around us.",
                            "spans": []
                        }
                    ],
                    "__typename": "HomepageBodyCarbon_positivePrimary"
                },
                "__typename": "HomepageBodyCarbon_positive"
            },
            {
                "type": "community",
                "label": null,
                "primary": {
                    "left_image": {
                        "dimensions": {
                            "width": 1501,
                            "height": 727
                        },
                        "alt": null,
                        "copyright": null,
                        "url": "https://images.prismic.io/carbonex/c681f7a7-cac5-4a23-929b-826cc07b8170_Carbonex-Crowd%403x.png?auto=compress,format"
                    },
                    "left_title": [
                        {
                            "type": "heading3",
                            "text": "Crowdfunding and crowdinvesting",
                            "spans": []
                        }
                    ],
                    "left_message": [
                        {
                            "type": "paragraph",
                            "text": "When we come together as a like-minded group, we power great projects for the good of the planet and humankind. Our combined funding and investments remove, reduce or avoid harmful levels of carbon and other greenhouse gasses.",
                            "spans": []
                        }
                    ],
                    "right_image": {
                        "dimensions": {
                            "width": 1501,
                            "height": 726
                        },
                        "alt": null,
                        "copyright": null,
                        "url": "https://images.prismic.io/carbonex/db6e9800-4645-4e92-a4d8-b72705e1402c_Carbonex-Community%403x.png?auto=compress,format"
                    },
                    "right_title": [
                        {
                            "type": "heading3",
                            "text": "A community that does more",
                            "spans": []
                        }
                    ],
                    "right_message": [
                        {
                            "type": "paragraph",
                            "text": "We are a global community that sees no limits to the positive impact we can have. That's why many of the projects you'll find here are improving lives by enhancing social, environmental and economic conditions around them.",
                            "spans": []
                        }
                    ],
                    "__typename": "HomepageBodyCommunityPrimary"
                },
                "__typename": "HomepageBodyCommunity"
            },
            {
                "type": "making_difference",
                "label": null,
                "primary": {
                    "section_title": [
                        {
                            "type": "heading1",
                            "text": "Together we can make a bigger difference",
                            "spans": []
                        }
                    ],
                    "__typename": "HomepageBodyMaking_differencePrimary"
                },
                "fields": [
                    {
                        "card_icon": {
                            "dimensions": {
                                "width": 465,
                                "height": 477
                            },
                            "alt": "You Icon",
                            "copyright": null,
                            "url": "https://images.prismic.io/carbonex/e3f49340-bb9d-4556-8a30-34b21cdd5f76_cx-Icon-You%403x.png?auto=compress,format"
                        },
                        "card_heading1": [
                            {
                                "type": "paragraph",
                                "text": "You",
                                "spans": []
                            }
                        ],
                        "card_body": [
                            {
                                "type": "paragraph",
                                "text": "Be carbon positive in a few taps with Carbonex. It's now easy to find, fund or invest in projects that will help slow climate change.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyMaking_differenceFields"
                    },
                    {
                        "card_icon": {
                            "dimensions": {
                                "width": 465,
                                "height": 477
                            },
                            "alt": "Your Business Icon",
                            "copyright": null,
                            "url": "https://images.prismic.io/carbonex/1491ad02-d69a-4e25-9e0a-23f6e0fb72bd_cx-Icon-Business%403x.png?auto=compress,format"
                        },
                        "card_heading1": [
                            {
                                "type": "paragraph",
                                "text": "Your business",
                                "spans": []
                            }
                        ],
                        "card_body": [
                            {
                                "type": "paragraph",
                                "text": "Becoming a 'Certified Carbon Positive' organisation is an obvious way of showing that your business cares about more than just doing business.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyMaking_differenceFields"
                    },
                    {
                        "card_icon": {
                            "dimensions": {
                                "width": 466,
                                "height": 477
                            },
                            "alt": "Your Team Icon",
                            "copyright": null,
                            "url": "https://images.prismic.io/carbonex/64f7b255-c686-45b6-b37e-6b6557469a9d_cx-Icon-Team%403x.png?auto=compress,format"
                        },
                        "card_heading1": [
                            {
                                "type": "paragraph",
                                "text": "Your team",
                                "spans": []
                            }
                        ],
                        "card_body": [
                            {
                                "type": "paragraph",
                                "text": "Unify your team around a common goal. Choose projects you can all support and believe in while taking your company to a carbon positive future.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyMaking_differenceFields"
                    },
                    {
                        "card_icon": {
                            "dimensions": {
                                "width": 465,
                                "height": 477
                            },
                            "alt": "Your Customer Icons",
                            "copyright": null,
                            "url": "https://images.prismic.io/carbonex/46681f5d-cc7a-41a1-9bf8-8e23b1ad0df6_cx-Icon-Customer%403x.png?auto=compress,format"
                        },
                        "card_heading1": [
                            {
                                "type": "paragraph",
                                "text": "Your customers",
                                "spans": []
                            }
                        ],
                        "card_body": [
                            {
                                "type": "paragraph",
                                "text": "Connect to your customers on Carbonex through a shared passion for the environment. Help them make purchases that will have a positive impact on the planet.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyMaking_differenceFields"
                    }
                ],
                "__typename": "HomepageBodyMaking_difference"
            },
            {
                "type": "two_column",
                "label": null,
                "primary": {
                    "section_title": [
                        {
                            "type": "heading1",
                            "text": "Two ways to make a positive change",
                            "spans": []
                        }
                    ],
                    "section_subheading": [
                        {
                            "type": "paragraph",
                            "text": "You can fund projects to become carbon positive or invest in businesses to receive a green return.",
                            "spans": []
                        }
                    ],
                    "__typename": "HomepageBodyTwo_columnPrimary"
                },
                "fields": [
                    {
                        "column_heading": [
                            {
                                "type": "heading1",
                                "text": "Crowdfunding",
                                "spans": []
                            }
                        ],
                        "column_subheading": [
                            {
                                "type": "heading1",
                                "text": "Fund great environmental projects around the world",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "Fund certified projects making a positive impact on climate change. Your funding helps you or your business become carbon positive.",
                                "spans": []
                            }
                        ],
                        "column_cta_text": "Try now for free",
                        "column_cta_url1": "/",
                        "__typename": "HomepageBodyTwo_columnFields"
                    },
                    {
                        "column_heading": [
                            {
                                "type": "heading1",
                                "text": "Crowdinvesting",
                                "spans": []
                            }
                        ],
                        "column_subheading": [
                            {
                                "type": "heading1",
                                "text": "Invest and launch new green businesses",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "Help grow new and carefully selected projects through Carbonex crowdinvesting. Earn a green return while making an environmental and social difference.",
                                "spans": []
                            }
                        ],
                        "column_cta_text": "Become a green investor",
                        "column_cta_url1": "/",
                        "__typename": "HomepageBodyTwo_columnFields"
                    }
                ],
                "__typename": "HomepageBodyTwo_column"
            },
            {
                "type": "image_cut-out_section",
                "label": null,
                "primary": {
                    "section_heading": [
                        {
                            "type": "heading1",
                            "text": "Crowdfunding",
                            "spans": []
                        }
                    ],
                    "section_subheading": [
                        {
                            "type": "heading1",
                            "text": "Back projects making a real impact",
                            "spans": []
                        }
                    ],
                    "cta_url": "/",
                    "cta_text": "Try now for free",
                    "cutout_image_1": {
                        "dimensions": {
                            "width": 3522,
                            "height": 3204
                        },
                        "alt": "Projects from the World",
                        "copyright": null,
                        "url": "https://images.prismic.io/carbonex/7a18bc57-6f8f-4e4a-b812-1d630747bbbd_Carbonex-Projects-Wide-lrg%403x.png?auto=compress,format"
                    },
                    "cutout_image_2": null,
                    "__typename": "HomepageBodyImage_cutOut_sectionPrimary"
                },
                "fields": [
                    {
                        "column_title": [
                            {
                                "type": "paragraph",
                                "text": "Explore diverse projects",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "From protecting rainforests to delivering clean energy and supporting local communities, you'll find inspiring projects on Carbonex.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyImage_cutOut_sectionFields"
                    },
                    {
                        "column_title": [
                            {
                                "type": "paragraph",
                                "text": "The crowd that can",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "Your support means the world, but needn't cost the earth. As part of a carbon positive community, our combined funding can help maintain successful projects for years ahead.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyImage_cutOut_sectionFields"
                    },
                    {
                        "column_title": [
                            {
                                "type": "paragraph",
                                "text": "Certified and trusted",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "Carbonex projects help protect our planet and are certified by leading organisations including the UNFCCC and Gold Standard.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyImage_cutOut_sectionFields"
                    },
                    {
                        "column_title": [
                            {
                                "type": "paragraph",
                                "text": "Projects doing more",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "These projects go beyond the environment. They promote social change, regenerate local communities, promote biodiversity and secure species survival.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyImage_cutOut_sectionFields"
                    }
                ],
                "__typename": "HomepageBodyImage_cutOut_section"
            },
            {
                "type": "crowdinvesting",
                "label": null,
                "primary": {
                    "section_heading": [
                        {
                            "type": "heading1",
                            "text": "Crowdinvesting",
                            "spans": []
                        }
                    ],
                    "section_subheading": [
                        {
                            "type": "heading1",
                            "text": "An investment that's good for you and the planet",
                            "spans": []
                        }
                    ],
                    "cta_url": "/",
                    "cta_text": "Become a green investor",
                    "__typename": "HomepageBodyCrowdinvestingPrimary"
                },
                "fields": [
                    {
                        "column_title": [
                            {
                                "type": "paragraph",
                                "text": "Join a powerful movement",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "Savvy, global investors already have innovative and profitable green businesses in their portfolios. Now, you can be part of this socially responsible investing revolution through Carbonex.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyCrowdinvestingFields"
                    },
                    {
                        "column_title": [
                            {
                                "type": "paragraph",
                                "text": "Grow green businesses",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "By investing in Carbonex projects, you're part of a bold new generation of environmental impact investors. Your investment will launch new ventures, see them take root and grow.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyCrowdinvestingFields"
                    },
                    {
                        "column_title": [
                            {
                                "type": "paragraph",
                                "text": "Get a fairer deal",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "We're committed to our projects over the long term. We set returns at an interest rate that's attractive to investors and fair to the business. Everyone wins.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyCrowdinvestingFields"
                    },
                    {
                        "column_title": [
                            {
                                "type": "paragraph",
                                "text": "Secure and ethical",
                                "spans": []
                            }
                        ],
                        "column_body": [
                            {
                                "type": "paragraph",
                                "text": "Every project supported by Carbonex is asset backed for added protection. You have the security to combine making financial returns with making a real difference.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyCrowdinvestingFields"
                    }
                ],
                "__typename": "HomepageBodyCrowdinvesting"
            },
            {
                "type": "animated_cards",
                "label": null,
                "primary": {
                    "section_heading": [
                        {
                            "type": "heading1",
                            "text": "The price of climate change is high",
                            "spans": []
                        }
                    ],
                    "section_subheading": [
                        {
                            "type": "paragraph",
                            "text": "Joining Carbonex is free",
                            "spans": []
                        }
                    ],
                    "body_title": [
                        {
                            "type": "paragraph",
                            "text": "Become carbon positive today",
                            "spans": []
                        }
                    ],
                    "body1": [
                        {
                            "type": "paragraph",
                            "text": "Join the Carbonex community and help fund amazing projects.",
                            "spans": []
                        }
                    ],
                    "cta_url": "/",
                    "cta_text": "Try now for free",
                    "left_image": {
                        "dimensions": {
                            "width": 6000,
                            "height": 8000
                        },
                        "alt": "Wind Power Project",
                        "copyright": null,
                        "url": "https://images.prismic.io/carbonex/776f2b61-5d70-478a-8fa1-e48957029f7f_Carbonex-Package-WindPower%402x.png?auto=compress,format"
                    },
                    "middle_image": {
                        "dimensions": {
                            "width": 6000,
                            "height": 8000
                        },
                        "alt": "Hydro Power Project",
                        "copyright": null,
                        "url": "https://images.prismic.io/carbonex/1348774d-70d2-4c79-860a-5047550c52ab_Carbonex-Package-HydroPower%402x.png?auto=compress,format"
                    },
                    "right_image": {
                        "dimensions": {
                            "width": 6000,
                            "height": 8000
                        },
                        "alt": "Global Forest Project",
                        "copyright": null,
                        "url": "https://images.prismic.io/carbonex/caf67f37-df29-43d6-99ff-d074677f8625_Carbonex-Package-GlobalForests%402x.png?auto=compress,format"
                    },
                    "__typename": "HomepageBodyAnimated_cardsPrimary"
                },
                "__typename": "HomepageBodyAnimated_cards"
            },
            {
                "type": "technology",
                "label": null,
                "primary": {
                    "section_heading": [
                        {
                            "type": "heading1",
                            "text": "Technology to take on a global challenge",
                            "spans": []
                        }
                    ],
                    "__typename": "HomepageBodyTechnologyPrimary"
                },
                "fields": [
                    {
                        "card_title": [
                            {
                                "type": "heading1",
                                "text": "Intelligent learning",
                                "spans": []
                            }
                        ],
                        "card_body": [
                            {
                                "type": "paragraph",
                                "text": "Carbonex is using artificial intelligence to develop models tracking the impact we're all making on our planet. Linked to environmental data and the state of global warming, these models help us accurately define the ways our members can be most effective.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyTechnologyFields"
                    },
                    {
                        "card_title": [
                            {
                                "type": "heading1",
                                "text": "Connecting our world",
                                "spans": []
                            }
                        ],
                        "card_body": [
                            {
                                "type": "paragraph",
                                "text": "Our technology is helping create a network linking people, organisations and great environmental projects. We bring the right people closer, united in the challenge against climate change.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyTechnologyFields"
                    },
                    {
                        "card_title": [
                            {
                                "type": "heading1",
                                "text": "Verifiable impact",
                                "spans": []
                            }
                        ],
                        "card_body": [
                            {
                                "type": "paragraph",
                                "text": "Harnessing blockchain and verifiable metrics, we ensure unprecedented accountability and transparency. We know for certain where every carbon credit is from. And know for certain that it is making a difference.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyTechnologyFields"
                    },
                    {
                        "card_title": [
                            {
                                "type": "heading1",
                                "text": "Safe and secure",
                                "spans": []
                            }
                        ],
                        "card_body": [
                            {
                                "type": "paragraph",
                                "text": "We use a variety of techniques and technology to ensure safe and secure business. This includes the known reassurance of blockchain processes and predictive machine learning.",
                                "spans": []
                            }
                        ],
                        "__typename": "HomepageBodyTechnologyFields"
                    }
                ],
                "__typename": "HomepageBodyTechnology"
            },
            {
                "type": "tagline",
                "label": null,
                "primary": {
                    "section_title": [
                        {
                            "type": "heading1",
                            "text": "Powered by a new global carbon exchange",
                            "spans": []
                        }
                    ],
                    "section_body": [
                        {
                            "type": "paragraph",
                            "text": "One planet, one platform. Buy and sell carbon credits easily on an international exchange.",
                            "spans": []
                        }
                    ],
                    "cta_url": "/",
                    "cta_text": "Learn more",
                    "__typename": "HomepageBodyTaglinePrimary"
                },
                "__typename": "HomepageBodyTagline"
            }
        ],
        "__typename": "Homepage"
  }
}

export type QueryProps = typeof data;
export default data;
