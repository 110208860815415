import styled, { css } from '@fe/styles';
import * as e from '@fe/components/Elements';

import CarbonEmissionsGraph from '@fe/public/images/carbon-emissions-graph.svg';

export const Container = styled.div`
`;
export const Title = styled(e.Text.h2) <{
  isColored?: boolean,
}>`
  color: ${(p) => p.isColored ? p.theme.color.darkGreen : p.theme.color.text};
  margin: 0;
  padding: 0;
`;
export const TitleContainer = styled.div`
  margin-bottom: 40px;
`;
export const ImageContainer = styled.div`
  max-width: 500px;
`;
export const Graph = styled(CarbonEmissionsGraph)`
  width: 100%;
`;
export const Button = styled(e.Button.Basic)`
  background: ${(p) => p.theme.color.darkGreen};
`;

export const Columns = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    ${(p) => p.theme.media.tablet`
      flex-direction: row;
    `}
  `,
  Column: styled.div<{
    maxWidth?: number,
  }>`
    ${(p) => p.theme.layout.paddingResizeCss}
    ${(p) => p.maxWidth && css`max-width: ${p.maxWidth}px;`}
    width: 100%;
  `,
};

export const Grid = {
  Container: styled.div`
    display: grid;
    justify-content: center;
    align-items: flex-start;
    grid-column-gap: ${(p) => p.theme.layout.spacingHorizontal};
    grid-template-columns: auto;
    ${(p) => p.theme.media.phablet`
      grid-template-columns: auto auto;
    `}
    ${(p) => p.theme.media.desktop`
      grid-template-columns: auto auto auto auto;
    `}
  `,
  Section: styled.div<{
    maxWidth?: number,
  }>`
    ${(p) => p.theme.layout.paddingResizeCss}
    ${(p) => p.maxWidth && css`max-width: ${p.maxWidth}px;`}
    width: 100%;
  `,
  Title: styled(e.Text.P) <{
    accent: string,
  }>`
    font-weight: 400;
    color: ${(p) => p.accent};
    margin-bottom: 0;
  `,
  Body: styled(e.Text.PSmall)`
  `,
};
