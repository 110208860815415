import * as React from 'react';
import styled, { css } from '@fe/styles';

const Container = styled.div<{
  size: number;
}>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  position: relative;
`;
const Left = styled.div<{
  isPlaying: boolean;
  size: number;
  color?: string;
}>`
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  position: absolute;
  background: ${p => p.color || `black`};
  height: 100%;
  top: 0;
  left: 0;
  width: 0;
  ${p => p.isPlaying && css`
    width: ${p => p.size*0.4}px;
  `}
`;
const Right = styled.div<{
  isPlaying: boolean;
  size: number;
  color?: string;
}>`
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  position: absolute;
  background: ${p => p.color || `black`};
  height: 100%;
  top: 0;
  width: 0;
  right: 100%;
  &::after {
    transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
    position: absolute;
    content: ' ';
    left: 100%;
    height: 0;
    top: 0;
    border-top: ${p => p.size/2}px solid transparent;
    border-bottom: ${p => p.size/2}px solid transparent;
    border-left: ${p => p.size*0.75}px solid ${p => p.color || `black`};
  }
  ${p => p.isPlaying && css`
    right: 0;
    width: ${p => p.size*0.4}px;
    &::after {
      border-left-width: 0;
    }
  `}
`;
export default class PlayToggle extends React.Component<{
  isPlaying: boolean;
  size?: number;
  color?: string;
}> {
  public static defaultProps = {
    size: 200,
    color: `white`,
  };

  public render = () => {
    const {
      isPlaying,
      size,
      color,
    } = this.props;
    return (
      <Container size={size!}>
        <Left size={size!} color={color} isPlaying={isPlaying} />
        <Right size={size!} color={color} isPlaying={isPlaying} />
      </Container>
    );
  }
}
