import * as React from 'react';

import * as e from '@fe/components/Elements';
import * as c from './components';
import * as Prismic from '@fe/components/Prismic';
import { HomepageBodyTagline } from '@graphql/types/server';
import ExpensiveComponent from '@fe/components/ExpensiveComponent';
import loadable from '@loadable/component';
const Globe = loadable(() => import('@fe/views/common/Globe'));

export default class IcoSection extends React.Component<{
  content: HomepageBodyTagline,
}> {

  public render = () => {
    const {
      content: {
        primary,
      },
    } = this.props;
    return (
      <c.Container>
        <c.GlobeContainer>
          <ExpensiveComponent>
            <Globe onControlsEnabledChanged={() => null} position={0}/>
          </ExpensiveComponent>
        </c.GlobeContainer>
        <c.Content>
          <e.Text.h2>
            <Prismic.RichText asText>
              {primary!.section_title}
            </Prismic.RichText>
          </e.Text.h2>
          <e.Text.P>
            <Prismic.RichText asText>
              {primary!.section_body}
            </Prismic.RichText>
          </e.Text.P>
          <c.Link
            variant={`secondary`}
            href={`https://ico.carbonex.co`}
            target={`_blank`}
          >
            {primary!.cta_text}
          </c.Link>
        </c.Content>
      </c.Container>
    );
  }
}
