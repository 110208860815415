import * as React from 'react';
import { HomepageBodyMaking_Difference } from '@graphql/types/server';

import * as Prismic from '@fe/components/Prismic';
import * as c from './components';
import * as Section from '../components';

import Carousel, { CarouselItem } from '@fe/views/common/Carousel';
import * as Size from '@fe/components/Size';

export default class MakingDifference extends React.Component<{
  content: HomepageBodyMaking_Difference,
}> {

  public render = () => {
    const {
      content: {
        fields,
        primary,
      },
    } = this.props;

    return (
      <Section.Container>
        <c.Content isLeft>
          <c.Title>
            <Prismic.RichText asText>
              {primary!.section_title}
            </Prismic.RichText>
          </c.Title>
          <Size.NotTablet>
            <Carousel>
              {fields!.map((card, index) => (
                <CarouselItem key={index}>
                  <c.Card.CarouselContainer>
                    <c.Card.IconContainer>
                      <c.Card.Icon
                      width={70}
                        {...Prismic.Image.getAttributes(card.card_icon)}
                      />
                    </c.Card.IconContainer>
                    <c.Card.Title>
                      <Prismic.RichText asText>
                        {card.card_heading1}
                      </Prismic.RichText>
                    </c.Card.Title>
                    <c.Card.Body>
                      <Prismic.RichText asText>
                        {card.card_body}
                      </Prismic.RichText>
                    </c.Card.Body>
                  </c.Card.CarouselContainer>
                </CarouselItem>
              ))}
            </Carousel>
          </Size.NotTablet>
          <Size.Tablet>
            <c.Cards>
              {fields!.map((card) => (
                <c.Card.Container key={card.card_heading1}>
                  <c.Card.IconContainer>
                    <c.Card.Icon
                      width={70}
                      {...Prismic.Image.getAttributes(card.card_icon)}
                    />
                  </c.Card.IconContainer>
                  <c.Card.Title>
                    <Prismic.RichText asText>
                      {card.card_heading1}
                    </Prismic.RichText>
                  </c.Card.Title>
                  <c.Card.Body>
                    <Prismic.RichText asText>
                      {card.card_body}
                    </Prismic.RichText>
                  </c.Card.Body>
                </c.Card.Container>
              ))}
            </c.Cards>
          </Size.Tablet>
        </c.Content>
      </Section.Container>
    );
  }
}
