import styled from '@fe/styles';
import * as e from '@fe/components/Elements';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  border: 30px solid white;
  border-left-width: 0;
  border-right-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  overflow: hidden;
  ${(p) => p.theme.media.phablet`
    border-width: 30px;
  `}
  ${(p) => p.theme.media.tablet`
    border-width: 60px;
  `}
`;
export const Content = styled.div`
  max-width: 600px;
  position: relative;
  z-index: ${(p) => p.theme.depth.infront};
  margin-bottom: 40px;
`;

export const GlobeContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const Link = styled(e.Button.default.withComponent(`a`))`
  text-decoration: none;
  text-transform: uppercase;
  transition: ${p => p.theme.transition.default};
`;
