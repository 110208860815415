import styled from '@fe/styles';
import * as e from '@fe/components/Elements';

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  ${(p) => p.theme.media.desktop`
    flex-direction: row;
    justify-content: center;
  `}
`;

export const Column = styled.div`
  flex: 1;
  background: white;
  ${(p) => p.theme.layout.largeMarginResizeCss}
  ${(p) => p.theme.layout.largePaddingResizeCss}
`;
export const ColumnContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 200px;
  max-width: 400px;
  margin: 0 auto;
`;
export const Image = styled(e.Images.Responsive)`
  max-width: 100%;
  /* margin: 0 auto; */
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    margin: 0 auto;
  }
`;
