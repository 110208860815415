import * as React from 'react';

export default class MountNotifier extends React.Component<{
  onMount: () => void;
}> {

  public componentDidMount = () => {
    this.props.onMount();
  }

  public render = () => {
    return this.props.children;
  }
}
