import styled from '@fe/styles';

import * as e from '@fe/components/Elements';

export const Content = styled(e.Layout.Content)`
  ${(p) => p.theme.layout.marginResizeCss}
`;
export const Title = styled(e.Text.h1)`
  text-align: center;
`;
export const Cards = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin: 0 auto;
  ${(p) => p.theme.media.largeDesktop`
    grid-template-columns: auto auto auto auto;
  `}
`;

export const Card = {
  Container: styled.div`
    background: white;
    flex: 1;
    padding: 40px;
    min-width: 250px;
    height: 100%;
  `,
  CarouselContainer: styled.div`
    background: white;
    flex: 1;
    padding: 40px;
    min-width: 225px;
    height: 100%;
  `,
  IconContainer: styled.div`
    width: 62px;
    height: 62px;
    margin-bottom: 16px;
  `,
  Icon: styled(e.Images.Responsive)`
  `,
  Title: styled(e.Text.h4)`
    color: ${(p) => p.theme.color.green};
  `,
  Body: styled(e.Text.PSmall)`
  `,
};
