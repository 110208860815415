import * as React from 'react';
import * as Spline from 'cubic-spline';

import Particle from '../particle';
import * as chance from 'chance';
const random = chance();
import Canvas from '../common/canvas';
export default class ParticleComponent extends React.Component<{
  className?: string,
  CanvasContainer?: any,
}> {
  private get spline() {
    return new Spline([
      -0.05,
      0.25,
      0.5,
      0.75,
      1.05,
    ], [
      0.35,
      0.4,
      0.4,
      0.2,
      0.25,
    ]);
  }

  private Canvas: Canvas;
  private circles: Array<Particle> = [];

  private onUpdate = () => {
    this.circles.forEach((c) => c.update());
  }

  private getCoordinates = (spline) => {
    const x = random.floating({ max: 1, min: 0 });
    return {
      xProportion: x,
      yProportion: spline.at(x),
    };
  }

  private onResize = (width, height) => {
    this.circles.forEach((c) => c.reposition(width, height));
  }
  private onMouseMove = (x, y) => {
    this.circles.forEach((c) => c.onMouseMove(x, y));
  }

  public onMount = (canvas, _canvas, context) => {
    this.Canvas = canvas;
    const spline = this.spline;
    // Density based on width
    for (let circleNo = 0; circleNo < 40; circleNo += 1) {
      const {
        xProportion,
        yProportion,
      } = this.getCoordinates(spline);
      const radius = random.floating({ max: 15, min: 2 });
      const color = random.pickone([`#00FFB8`, `#008994`, `#00FFB8`, `#00FFB8`]);
      const circle = new Particle({
        xProportion,
        yProportion,
        radius,
        color,
        width: this.Canvas.width,
        height: this.Canvas.height,
        c: context,
      });
      circle.draw();
      this.circles.push(circle);
    }
  }

  public render = () => {
    return (
      <Canvas
        onMount={this.onMount}
        onUpdate={this.onUpdate}
        onResize={this.onResize}
        onMouseMove={this.onMouseMove}
        className={this.props.className}
        CanvasContainer={this.props.CanvasContainer}
      />
    );
  }
}
