import Circle, { IShape } from './shape/circle';
import * as geometry from './geometry';
import * as chance from 'chance';

const random = chance();

interface IParticle extends IShape {
  radius: number;
  color: string;
  lineWidth?: number;
  strokeColor?: string;
  isStationary?: boolean;
}
export default class Particle extends Circle {
  private opacity: number;
  private color: string;
  private strokeColor: string | undefined;
  private lineWidth: number | undefined;
  private floatConfig: any;
  private mouseAdjustment: {
    x: number;
    y: number;
    interactDistance: number;
    distanceFactor: number;
  };
  private startAnim: {
    start: number;
    end: number;
    delay: number;
    duration: number;
  };

  public radius: number;
  public isStationary: boolean;

  constructor(config: IParticle) {
    super(config);
    this.radius = config.radius;
    this.lineWidth = config.lineWidth;
    this.strokeColor = config.strokeColor;
    this.color = config.color;
    this.opacity = 0;
    const delay = random.integer({ min: 0, max: 3000 });
    const duration = random.integer({ min: 0, max: 5000 });
    this.startAnim = {
      delay,
      duration,
      start: this.init + delay,
      end: this.init + delay + duration,
    };
    this.isStationary = config.isStationary;
    this.floatConfig = {
      xDistance: random.floating({ max: 20, min: 0 }),
      yDistance: random.floating({ max: 20, min: 0 }),
      xSpeed: random.floating({ max: 3000, min: 1500 }),
      ySpeed: random.floating({ max: 3000, min: 1500 }),
    };
    this.mouseAdjustment = {
      x: 0,
      y: 0,
      interactDistance: 100,
      distanceFactor: random.integer({ min: 2, max: 6 }),
    };
  }

  private initAnimation = () => {
    if (this.startAnim.end < this.current) {
      this.opacity = 1;
    } else if (this.startAnim.start < this.current) {
      const percentage = (this.current - this.startAnim.start) / this.startAnim.duration;
      this.opacity = percentage;
    }
  }
  private floatMovement = (diff: number) => {
    if (!this.isStationary) {
      this.x = this.xAnchor + this.floatConfig.xDistance * Math.sin(diff / this.floatConfig.xSpeed);
      this.y = this.yAnchor + this.floatConfig.yDistance * Math.sin(diff / this.floatConfig.ySpeed);
    }
  }
  private adjustForMouse = () => {
    this.x += this.mouseAdjustment.x;
    this.y += this.mouseAdjustment.y;
  }

  public onMouseMove = (mouseX, mouseY) => {
    if (!this.isStationary) {
      const {
        interactDistance,
      } = this.mouseAdjustment;
      const originalX = this.x + this.mouseAdjustment.x;
      const originalY = this.y + this.mouseAdjustment.y;
      const distanceFromMouse = geometry.distance(originalX, originalY, mouseX, mouseY);
      const increment = 0.1;
      if (distanceFromMouse < interactDistance) {
        const linearProportion = (interactDistance - distanceFromMouse) / interactDistance;
        const newx = (geometry.positionAlongLine(originalX, mouseX, linearProportion) - originalX) / this.mouseAdjustment.distanceFactor;
        const xValue = Math.abs(newx);
        const xSign = newx / xValue;
        const newy = (geometry.positionAlongLine(originalY, mouseY, linearProportion) - originalY) / this.mouseAdjustment.distanceFactor;
        const yValue = Math.abs(newy);
        const ySign = newy / yValue;
        this.mouseAdjustment.x = -xSign * Math.min(xValue, Math.abs(this.mouseAdjustment.x) + increment);
        this.mouseAdjustment.y = -ySign * Math.min(yValue, Math.abs(this.mouseAdjustment.y) + increment);
      } else {
        const xValue = Math.abs(this.mouseAdjustment.x);
        const xSign = this.mouseAdjustment.x / xValue;
        const yValue = Math.abs(this.mouseAdjustment.y);
        const ySign = this.mouseAdjustment.y / yValue;
        this.mouseAdjustment.x = -xSign * Math.max(0, Math.abs(this.mouseAdjustment.x) - increment);
        this.mouseAdjustment.y = -ySign * Math.max(0, Math.abs(this.mouseAdjustment.y) - increment);
        this.mouseAdjustment.x = 0;
        this.mouseAdjustment.y = 0;
      }
    }
  }

  public draw = () => {
    this.c.save();
    this.c.globalAlpha = this.opacity;
    this.c.beginPath();
    this.c.arc(
      this.x,
      this.y,
      Math.abs(this.radius),
      0,
      Math.PI * 2,
    );
    // this.c.filter = `blur(4px)`;
    // Adjust size
    // Grow in at the start
    // Opacity
    this.c.shadowBlur = this.radius / 4;
    this.c.shadowOffsetX = this.radius / 8;
    this.c.shadowOffsetY = this.radius / 8;
    this.c.shadowColor = `rgba(0,0,0,0.1)`;
    this.c.fillStyle = this.color;
    this.c.strokeStyle = this.strokeColor || `rgba(0,0,0,0.5)`;
    this.c.lineWidth = this.lineWidth || 0.05;
    this.c.fill();
    this.c.stroke();
    this.c.restore();
  }

  public update = () => {
    this.initAnimation();
    this.floatMovement(this.diff);
    this.adjustForMouse();

    this.draw();
  }
}
