import * as React from 'react';
import { Settings } from 'react-slick';
import { loadLink } from '@fe/components/libraryLoader';
import * as c from './components';
import DefaultArrow from './arrow';

export default class Carousel extends React.Component<Settings & {
  loadingMessage?: string,
  Arrow: typeof DefaultArrow,
}, {
  isLoading: boolean,
  isMainLoaded: boolean,
  isThemeLoaded: boolean,
  isError: boolean,
}> {

  public static defaultProps = {
    Arrow: DefaultArrow,
  };

  public state = {
    isLoading: false,
    isMainLoaded: false,
    isThemeLoaded: false,
    isError: false,
  };

  public componentDidMount = () => {
    loadLink({
      src: `https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css`,
      id: `slick-main-css`,
    })
      .then(() => {
        console.log('loaded main')
        this.setState({
          isMainLoaded: true,
        });
      })
      .catch(() => {
        console.log('failed main')
        this.setState({
          isError: true,
        });
      });
    loadLink({
      src: `https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css`,
      id: `slick-theme-css`,
    })
      .then(() => {
        console.log('loaded theme')
        this.setState({
          isThemeLoaded: true,
        });
      })
      .catch(() => {
        console.log('failed theme')
        this.setState({
          isError: true,
        });
      });
  }

  public render = () => {
    const {
      isLoading,
      isMainLoaded,
      isThemeLoaded,
      isError,
    } = this.state;
    const {
      onInit,
      loadingMessage,
      Arrow,
    } = this.props;

    if (isError) return null;
    return (
      <c.LoadingContainer isLoading={isLoading} message={loadingMessage}>
        <>
          {(isMainLoaded && isThemeLoaded) && (
            <c.StyledSlider
              nextArrow={(
                <Arrow direction={`right`} />
              )}
              prevArrow={(
                <Arrow direction={`left`}/>
              )}
              lazyLoad={`progressive`}
              onInit={() => {
                this.setState({
                  isLoading: false,
                });
                if (onInit) onInit();
              }}
              {...this.props}
            />
          )}
        </>
      </c.LoadingContainer>
    );
  }
}
