import styled from '@fe/styles';

import PositiveIconSvg from '@fe/public/icons/positive-icon.svg';
import PositiveChartSvg from '@fe/public/images/positive-chart.svg';

export const PlusContainer = styled.div`

`;
export const Plus = styled(PositiveIconSvg)`
  width: 48px;
  margin: 0 auto;
`;

export const PositiveChartContainer = styled.div`
  ${(p) => p.theme.layout.largePaddingResizeCss}
`;
export const PositiveChart = styled(PositiveChartSvg)`
  max-width: 840px;
  width: 100%;
`;
