import * as React from 'react';
import {
  HomepageBody,
  HomepageBodyCommunity,
  HomepageBodyCarbon_Positive,
  HomepageBodyMaking_Difference,
  HomepageBodyTwo_Column,
  HomepageBodyImage_CutOut_Section,
  HomepageBodyCrowdinvesting,
  HomepageBodyTagline,
  HomepageBodyAnimated_Cards,
  HomepageBodyTechnology,
} from '@graphql/types/server';

import Community from './Community';
import CarbonPostive from './CarbonPositive';
import MakingDifference from './MakingDifference';
import TwoColumn from './TwoColumn';
import CutOut from './CutOut';
import CrowdInvesting from './CrowdInvesting';
import Projects from './Projects';
import Technology from './Technology';
import Ico from './Ico';

export default class Section extends React.Component<{
  content: HomepageBody,
}> {

  public render = () => {
    const {
      content,
    } = this.props;
    switch (content.type) {
      case `community`: return <Community content={content as HomepageBodyCommunity} />;
      case `carbon_positive`: return <CarbonPostive content={content as HomepageBodyCarbon_Positive} />;
      case `making_difference`: return <MakingDifference content={content as HomepageBodyMaking_Difference} />;
      case `two_column`: return <TwoColumn content={content as HomepageBodyTwo_Column} />;
      case `image_cut-out_section`: return <CutOut content={content as HomepageBodyImage_CutOut_Section} />;
      case `crowdinvesting`: return <CrowdInvesting content={content as HomepageBodyCrowdinvesting} />;
      case `technology`: return <Technology content={content as HomepageBodyTechnology} />;
      case `animated_cards`: return <Projects content={content as HomepageBodyAnimated_Cards} />;
      case `tagline`: return <Ico content={content as HomepageBodyTagline} />;
      // default: throw Error(`${content.type} section has not been defined`);
      default: {
        console.error(`${content.__typename} section has not been defined`, content);
        return null;
      }
    }
  }
}
