import styled, { css } from '@fe/styles';
import Slider from 'react-slick';
import * as e from '@fe/components/Elements';

export const LoadingContainer = styled(e.Loading.Contained)``;
export const StyledSlider = styled(Slider)`
  .slick-slide, .slick-slide *{ outline: none !important; }
`;
export const Arrow = styled(e.Icon.Arrow)`
  color: black !important;
  transition: ${(p) => p.theme.transition.default};
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  width: 30px;
  height: auto;

  ${(p) => p.direction === `left` ? css`
    left: 10px;
  ` : css`
    right: 10px;
  `}
`;
export const ArrowContainer = styled.div<{
  direction: `left` | `right`,
}>`
  top: 0;
  bottom: 0;
  z-index: ${(p) => p.theme.depth.infront};
  /* background-color: rgba(0,0,255, 0.2); */
  width: 40px;
  height: 100%;
  transform: none;
  :hover ${Arrow} {
    ${(p) => p.direction === `left` ? css`
      left: 2px;
    ` : css`
      right: 2px;
    `}
  }
`;
