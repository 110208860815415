import * as React from 'react';
import styled from '@fe/styles';

const DefaultCanvasContainer = styled.div`
  width: 100%;
  height: 100vh;

`;
const Canvas = styled.canvas`
`;

export default class CanvasComponent extends React.Component<{
  style?: any;
  onUpdate: () => void;
  onMount: (self: CanvasComponent, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => void;
  onMouseMove?: (x, y) => void;
  onResize?: (width, height) => void;
  className?: string,
  CanvasContainer?: typeof DefaultCanvasContainer;
}> {
  public static defaultProps = {
    CanvasContainer: DefaultCanvasContainer,
  };
  private ContainerRef = React.createRef<HTMLElement>();
  public renderContext: CanvasRenderingContext2D;
  public Canvas = React.createRef<HTMLCanvasElement>();
  private get containerElement() { return this.ContainerRef.current!; }
  public get canvasElement() { return this.Canvas.current!; }
  public get width() { return this.Canvas.current!.width; }
  public get height() { return this.Canvas.current!.height; }

  private update = () => {
    this.renderContext.clearRect(0, 0, this.width, this.height);
    this.props.onUpdate();
    window.requestAnimationFrame(this.update);
  }

  private updateDimensions = () => {
    this.canvasElement.width = this.containerElement.clientWidth;
    this.canvasElement.height = this.containerElement.clientHeight;
    if (this.props.onResize) this.props.onResize(this.canvasElement.width, this.canvasElement.height);
  }

  private registerOnMouseMove = () => {
    if (this.props.onMouseMove) {
      this.canvasElement.onmousemove = (e) => {
        this.props.onMouseMove!(e.clientX - this.canvasElement.offsetLeft, e.clientY - this.canvasElement.offsetTop);
      };
    }
  }

  public componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);

    this.renderContext = this.Canvas.current!.getContext('2d')!;

    this.props.onMount(this, this.canvasElement, this.renderContext);

    this.registerOnMouseMove();

    this.update();
  }
  public componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public render = () => {
    const {
      CanvasContainer,
    } = this.props;
    if (!CanvasContainer) throw new Error(`Canvas container needs to be defined`);
    return (
      <CanvasContainer
        innerRef={this.ContainerRef}
        className={this.props.className}
        style={this.props.style}
      >
        <Canvas innerRef={this.Canvas} />
      </CanvasContainer>
    );
  }
}
