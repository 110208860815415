import * as moment from 'moment';

export interface IShape {
  c: CanvasRenderingContext2D;
  xProportion: number;
  yProportion: number;
  width: number;
  height: number;
}
export default class Circle {

  private xProportion: number;
  private yProportion: number;
  private width: number;
  private height: number;
  protected init: number;
  protected c: CanvasRenderingContext2D;
  protected xAnchor: number;
  protected yAnchor: number;
  protected x: number;
  protected y: number;

  protected get current() {
    return moment().valueOf();
  }
  protected get diff() {
    return this.current - this.init;
  }

  constructor(config: IShape) {
    this.init = moment().valueOf();
    this.c = config.c;
    this.xProportion = config.xProportion;
    this.yProportion = config.yProportion;
    this.reposition(config.width, config.height);

    this.x = this.xAnchor;
    this.y = this.yAnchor;
  }

  public reposition = (width, height) => {
    this.width = width;
    this.height = height;
    this.xAnchor = this.xProportion * this.width;
    this.yAnchor = this.yProportion * this.height;
    this.x = this.xAnchor;
    this.y = this.yAnchor;
  }
}
