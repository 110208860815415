import styled, { css } from '@fe/styles';
import SplineParticles from '../../Particles/views/spline';
import * as e from '@fe/components/Elements';

export const Container = styled.div`

`;
export const ColorText = styled.div`
  color: ${(p) => p.theme.color.text};
`;
export const ColorGreen = styled.div`
  color: ${(p) => p.theme.color.green};
`;

const BaseCard = css`
  max-width: 180px;
  display: inline-block;
  position: relative;
  z-index: ${(p) => p.theme.depth.infront};
  ${(p) => p.theme.media.phablet`
    max-width: 220px;
  `}
  ${(p) => p.theme.media.tablet`
    max-width: 320px;
  `}
  img {
    box-shadow: 2px 2px 22px 0px rgba(0,0,0,0.44);
    border-radius: 10px;
    width: 100%;
  }
`;
export const MiddleCard = styled.div<{
  isVisible: boolean;
}>`
  ${BaseCard}
  z-index: ${(p) => p.theme.depth.front};
  ${(p) => p.isVisible && css`
    transform: translate3d(0, 0, 0);
  `}
`;
export const SideCard = styled.div<{
  isVisible: boolean;
}>`
  ${BaseCard}
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 1s ${(p) => p.theme.easing.easeOutCubic};
`;
export const LeftCard = styled(SideCard)`
  transform: translate3d(50%, 0, -150px) rotateY(-0deg);
  ${(p) => p.isVisible && css`
    transform: translate3d(80%, 0, -150px) rotateY(-0deg);
  `}
`;
export const RightCard = styled(SideCard)`
  transform: translate3d(-50%, 0, -150px) rotateY(0deg);
  ${(p) => p.isVisible && css`
    transform: translate3d(-80%, 0, -150px) rotateY(0deg);
  `}
`;
export const ProjectCards = styled.div`
  position: relative;
  perspective: 999px;
  &:hover {
    ${LeftCard} {
      transform: translate3d(80%, 0, -150px) rotateY(-8deg);
    }
    ${RightCard} {
      transform: translate3d(-80%, 0, -150px) rotateY(8deg);
    }
  }
`;

export const ParticlesContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
`;
export const BackgroundParticles = styled(SplineParticles)`
  position: absolute;
  right: 0;
  bottom: -100px;
  left: 0;
  z-index: 0;
  height: 200px;
`;
export const CanvasContainer = styled.div`
  width: 100%;
  height: 200px;
`;

export const FooterContent = styled(e.Layout.Content)`
  position: relative;
  z-index: ${(p) => p.theme.depth.infront};
  text-align: center;
`;
