import * as React from 'react';
import { defaults } from '@fe/styles';
import { HomepageBodyTwo_Column } from '@graphql/types/server';

import * as Prismic from '@fe/components/Prismic';
import * as e from '@fe/components/Elements';
import * as c from './components';
import * as Section from '../components';
import * as Size from '@fe/components/Size';
import ParticlesAroundCircle from '../../Particles/views/particlesAroundCircle';
import { setParam } from '@fe/views/common/routeHandler';

export default class TwoColumnSection extends React.Component<{
  content: HomepageBodyTwo_Column,
}> {

  public render = () => {
    const {
      content: {
        primary,
        fields,
      },
    } = this.props;

    const getColor = (index: number) => index === 1 ? defaults.color.darkGreen : defaults.color.green;
    const getColors = (index: number) => index === 1 ? [`#00FFB8`, `#008994`, `#008994`, `#008994`] : [`#00FFB8`, `#008994`, `#00FFB8`, `#00FFB8`];

    return (
      <Section.Container isWhite>
        <c.Container>
          <e.Layout.Content style={{ maxWidth: `1200px` }}>
            <e.Text.h1>
              <Prismic.RichText asText>
                {primary!.section_title}
              </Prismic.RichText>
            </e.Text.h1>
            <e.Text.P>
              <Prismic.RichText asText>
                {primary!.section_subheading}
              </Prismic.RichText>
            </e.Text.P>
          <Size.NotTablet>
            {fields!.map((column, index) => (
              <c.Column>
                <c.Circle>
                  <ParticlesAroundCircle colors={getColors(index)} CanvasContainer={c.CanvasContainer} />
                  <c.CircleTitle accent={getColor(index)}>
                    <Prismic.RichText asText>
                      {column.column_heading}
                    </Prismic.RichText>
                  </c.CircleTitle>
                </c.Circle>
                <c.Columns.Title accent={getColor(index)}>
                  <Prismic.RichText asText>
                    {column.column_subheading}
                  </Prismic.RichText>
                </c.Columns.Title>
                <c.Columns.Button accent={getColor(index)}>
                  {column.column_cta_text}
                </c.Columns.Button>
              </c.Column>
            ))}
          </Size.NotTablet>
          <Size.Tablet>
            <c.Columns.Container>
              {fields!.map((column, index) => (
                <>
                  <c.Circle>
                    <ParticlesAroundCircle colors={getColors(index)} CanvasContainer={c.CanvasContainer} />
                  </c.Circle>
                  <c.Columns.Title accent={getColor(index)}>
                    <Prismic.RichText asText>
                      {column.column_subheading}
                    </Prismic.RichText>
                  </c.Columns.Title>
                  <c.Columns.Body>
                    <Prismic.RichText asText>
                      {column.column_body}
                    </Prismic.RichText>
                  </c.Columns.Body>
                  <c.Columns.ButtonContainer>
                    <c.Columns.Button
                      accent={getColor(index)}
                      onClick={() => setParam(`auth`, `register`)}
                    >
                      {column.column_cta_text}
                    </c.Columns.Button>
                  </c.Columns.ButtonContainer>
                </>
              ))}
            </c.Columns.Container>
          </Size.Tablet>
        </e.Layout.Content>
        </c.Container>
      </Section.Container>
    );
  }
}
