import styled, { css } from '@fe/styles';

export const Container = styled.section<{
  isWhite?: boolean,
}>`
  min-height: 100%;
  min-height: calc(100vh - 68px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(p) => p.isWhite && css`background: white;`};
  ${(p) => p.theme.layout.largePaddingResizeCss};
  & {
    padding-left: 0;
    padding-right: 0;
  }
  & > * {
    max-width: 100%;
  }
`;
