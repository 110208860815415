import * as React from 'react';
import { HomepageBodyCarbon_Positive } from '@graphql/types/server';

import * as Prismic from '@fe/components/Prismic';
import * as e from '@fe/components/Elements';
import * as c from './components';
import * as Section from '../components';

export default class CarbonPositiveSection extends React.Component<{
  content: HomepageBodyCarbon_Positive,
}> {

  public render = () => {
    const {
      content: {
        primary,
      },
    } = this.props;

    return (
      <Section.Container>
        <div>
          <e.Layout.Content>
            <c.PlusContainer>
              <c.Plus />
            </c.PlusContainer>
            <e.Text.h2>
              <Prismic.RichText asText>
                {primary!.title}
              </Prismic.RichText>
            </e.Text.h2>
            <e.Text.P>
              <Prismic.RichText asText>
                {primary!.message}
              </Prismic.RichText>
            </e.Text.P>
          </e.Layout.Content>
          <c.PositiveChartContainer>
            <c.PositiveChart />
          </c.PositiveChartContainer>
        </div>
      </Section.Container>
    );
  }
}
