import * as Sentry from '@sentry/browser';

export default ({
  src,
  id,
  rel = `stylesheet`,
  type = `text/css`,
  alwaysAdd = false,
}) => {
  return new Promise((resolve, reject) => {
    let link = alwaysAdd ? null : document.getElementById(id) as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.href = src;
      link.id = id;
      link.rel = rel;
      link.type = type;
    }
    const hasLoaded = !!link.sheet;
    if (hasLoaded) {
      resolve();
    } else {
      const onLoad = () => {
        link!.removeEventListener(`load`, onLoad);
        resolve();
      };
      const onError = (err) => {
        link!.removeEventListener(`error`, onError);
        Sentry.captureMessage(`Failed to load link ${id}`);
        Sentry.captureException(err);
        reject(err);
      };
      link.addEventListener(`load`, onLoad);
      link.addEventListener(`error`, onError);
    }
    if (document.body) {
      document.body.appendChild(link);
    } else {
      const message = `No body to attach link to for ${id}`;
      Sentry.captureException(new Error(message));
      reject(message);
    }
  });
};
