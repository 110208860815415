import * as React from 'react';
import { HomepageBodyAnimated_Cards } from '@graphql/types/server';

import * as Prismic from '@fe/components/Prismic';
import * as e from '@fe/components/Elements';
import * as c from './components';
import * as Section from '../components';
import { setParam } from '@fe/views/common/routeHandler';
import { Waypoint } from 'react-waypoint';

export default class Projects extends React.Component<{
  content: HomepageBodyAnimated_Cards,
}, {
  isVisible: boolean,
}> {

  public state = {
    isVisible: false,
  };

  private setIsVisible = (isVisible) => {
    this.setState({ isVisible });
  }

  public render = () => {
    const {
      isVisible,
    } = this.state;
    const {
      content: {
        primary,
      },
    } = this.props;

    return (
      <Section.Container>
        <e.Layout.Content>
          <e.Text.h3>
            <c.ColorText>
              <Prismic.RichText asText>
                {primary!.section_heading}
              </Prismic.RichText>
            </c.ColorText>
            <c.ColorGreen>
              <Prismic.RichText asText>
                {primary!.section_subheading}
              </Prismic.RichText>
            </c.ColorGreen>
          </e.Text.h3>
        </e.Layout.Content>
        <c.ParticlesContainer>
          <c.BackgroundParticles CanvasContainer={c.CanvasContainer} />
          <Waypoint
            scrollableAncestor={typeof document !== `undefined` ? document.body : undefined}
            topOffset={300}
            bottomOffset={300}
            onEnter={() => this.setIsVisible(true)}
            onLeave={() => this.setIsVisible(false)}
          >
            <div>
              <c.ProjectCards>
                <c.LeftCard isVisible={isVisible}>
                  <e.Images.Responsive
                    width={320}
                    disableLazyload
                    {...Prismic.Image.getAttributes(primary!.left_image)}
                  />
                </c.LeftCard>
                <c.MiddleCard isVisible={isVisible}>
                  <e.Images.Responsive
                    width={320}
                    disableLazyload
                    {...Prismic.Image.getAttributes(primary!.middle_image)}
                  />
                </c.MiddleCard>
                <c.RightCard isVisible={isVisible}>
                  <e.Images.Responsive
                    width={320}
                    disableLazyload
                    {...Prismic.Image.getAttributes(primary!.right_image)}
                  />
                </c.RightCard>
              </c.ProjectCards>
            </div>
          </Waypoint>
        </c.ParticlesContainer>
        <c.FooterContent>
          <e.Text.P>
            <c.ColorGreen>
              <Prismic.RichText asText>
                {primary!.section_heading}
              </Prismic.RichText>
            </c.ColorGreen>
            <c.ColorText>
              <Prismic.RichText asText>
                {primary!.section_subheading}
              </Prismic.RichText>
            </c.ColorText>
          </e.Text.P>
          <e.Button.Basic onClick={() => setParam(`auth`, `register`)}>
            {primary!.cta_text}
          </e.Button.Basic>
        </c.FooterContent>
      </Section.Container>
    );
  }
}
