
export const distance = (startX: number, startY: number, endX: number, endY: number) => {
  const xDist = Math.pow(endX - startX, 2);
  const yDist = Math.pow(endY - startY, 2);
  return Math.sqrt(xDist + yDist);
};
export const positionAlongLine = (pointFrom: number, pointTo: number, percentage: number) => {
  return ((pointTo - pointFrom) * percentage) + pointFrom;
};

export const getRadius = (point) => {
  return Math.sqrt((point.x ** 2) + (point.y ** 2));
};
export const putOnSphere = (point, radius) => {
  const normalisingValue = 1 / getRadius(point);
  return {
    x: normalisingValue * radius * point.x,
    y: normalisingValue * radius * point.y,
  };
};
