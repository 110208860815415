import * as React from 'react';

import Particle from '../particle';
import * as chance from 'chance';
const random = chance();
import Canvas from '../common/canvas';
export default class ParticleComponent extends React.Component<{
  style?: any,
  className?: string,
  CanvasContainer?: any,
  backgroundColor?: string,
  minX: number,
  maxX: number,
  minY: number,
  maxY: number,
}> {

  public static defaultProps = {
    backgroundColor: `transparent`,
  };

  private Canvas: Canvas;
  private circles: Array<Particle> = [];

  private onUpdate = () => {
    this.circles.forEach((c) => c.update());
  }

  private getCoordinates = () => {
    const {
      minX,
      maxX,
      minY,
      maxY,
    } = this.props;
    const x = random.floating({ min: minX, max: maxX });
    const y = random.floating({ min: minY, max: maxY });
    return {
      xProportion: x,
      yProportion: y,
    };
  }

  private onResize = (width, height) => {
    this.circles.forEach((c) => c.reposition(width, height));
  }
  private onMouseMove = (x, y) => {
    this.circles.forEach((c) => c.onMouseMove(x, y));
  }

  public onMount = (canvas, _canvas, context) => {
    const {
      backgroundColor,
    } = this.props;
    this.Canvas = canvas;
    // Top left
    for (let circleNo = 0; circleNo < 8; circleNo += 1) {
      const {
        xProportion,
        yProportion,
      } = this.getCoordinates();
      const radius = random.floating({ max: 60, min: 2 });
      const fillColor = random.pickone([`#00FFB8`, backgroundColor, backgroundColor]);
      const strokeColor = `#00FFB8`;
      const lineWidth = 1;
      const circle = new Particle({
        xProportion,
        yProportion,
        radius,
        strokeColor,
        lineWidth,
        color: fillColor,
        width: this.Canvas.width,
        height: this.Canvas.height,
        c: context,
      });
      circle.draw();
      this.circles.push(circle);
    }
  }

  public render = () => {
    return (
      <Canvas
        style={this.props.style}
        onMount={this.onMount}
        onUpdate={this.onUpdate}
        onResize={this.onResize}
        onMouseMove={this.onMouseMove}
        className={this.props.className}
        CanvasContainer={this.props.CanvasContainer}
      />
    );
  }
}
