import LinkResolver from './linkResolver';
import HtmlSerialiser from './htmlSerialiser';
import * as Link from './link';
import * as Image from './image';
import RichText from './richText';
import { RichText as PrismicRichText } from 'prismic-reactjs';

const {
  asText,
} = PrismicRichText;

export {
  RichText,
  Image,
  Link,
  LinkResolver,
  HtmlSerialiser,
  asText,
};
