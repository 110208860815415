import styled from '@fe/styles';

import * as e from '@fe/components/Elements';

export const Container = styled.div`
`;

export const Circle = styled.div`
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  grid-row-start: 1;
  grid-row-end: 1;
`;
export const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
export const CircleTitle = styled(e.Text.h3)<{
  accent: string,
}>`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  transform: translate(0, -50%);
  color: ${(p) => p.accent};
`;

export const Column = styled.div`
  ${(p) => p.theme.layout.paddingResizeCss}
  max-width: 480px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
`;
const maxWidth = `420px`;
export const Columns = {
  Container: styled.div`
    display: grid;
    flex-direction: column;
    justify-content: space-evenly;
    justify-items: stretch;
    grid-template-columns: auto auto;
    text-align: center;
    width: 100vw;
    max-width: 100%;
    grid-column-gap: ${(p) => p.theme.layout.spacingHorizontal};
    grid-row-gap: ${(p) => p.theme.layout.spacingVertical};
  `,
  Title: styled(e.Text.h3) <{
    accent: string,
  }>`
    color: ${(p) => p.accent};
    grid-row-start: 2;
    grid-row-end: 2;
    max-width: ${maxWidth};
  `,
  Body: styled(e.Text.P)`
    grid-row-start: 3;
    grid-row-end: 3;
    max-width: ${maxWidth};
  `,
  ButtonContainer: styled.div`
    grid-row-start: 4;
    grid-row-end: 4;
    max-width: ${maxWidth};
  `,
  Button: styled(e.Button.Basic)<{
    accent: string,
  }>`
    background: ${(p) => p.accent};
  `,
};
