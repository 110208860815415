import * as React from 'react';
import { RichText } from '@fe/components/Prismic';

import * as e from '@fe/components/Elements';
import * as c from './components';
import { Homepage } from '@graphql/types/server';
import { setParam } from '@fe/views/common/routeHandler';
import PlayToggle from '@fe/components/playToggle';


export default class Hero extends React.Component<{
  content: Homepage,
}, {
  isPlaying: boolean,
  canPlay: boolean,
}> {
  private videoRef = React.createRef<HTMLVideoElement>();
  public state = {
    isPlaying: false,
    canPlay: false,
  };

  private togglePlaying = async () => {
    const {
      isPlaying,
    } = this.state;
    const video = this.videoRef.current!;
    if (isPlaying) {
      this.setState({ isPlaying: false });
      await video.pause();
    } else {
      this.setState({ isPlaying: true });
      await video.play();
    }
  }

  public render = () => {
    const { content } = this.props;
    const { isPlaying } = this.state;

    return (
      <c.Container>
        <e.Layout.Content>
          <e.Text.h1>
            <RichText asText>
              {content.hero_title}
            </RichText>
          </e.Text.h1>
          <e.Text.P>
            <RichText asText>
              {content.hero_paragraph}
            </RichText>
          </e.Text.P>
          <div>
            <e.Button.default onClick={() => setParam(`auth`, `register`)}>
              {content.cta}
            </e.Button.default>
          </div>
        </e.Layout.Content>
        <c.LaptopSection>
          <c.BackgroundParticles />
          <c.LaptopContainer>
            <c.VideoContainer>
              <c.Video
                innerRef={this.videoRef}
                playsInline
                loop
                preload='auto'
                // onLoadedData
              >
                <source src={(content.video! as any).url} onCanPlay={() => this.setState({ canPlay: true })} type={`video/mp4`} />
              </c.Video>
              <c.Controls onClick={() => this.togglePlaying()}>
                <PlayToggle
                  isPlaying={isPlaying}
                  size={80}
                  color={`white`}
                />
              </c.Controls>
            </c.VideoContainer>
            <c.Laptop
              disableLazyload
              src={content.laptop!.url}
              sizes={`100%`}
            />
            <c.LaptopShadow
              disableLazyload
              src={content.shadow!.url}
              sizes={`100%`}
            />
          </c.LaptopContainer>
        </c.LaptopSection>
      </c.Container>
    );
}
}
