import * as React from 'react';
import { HomepageBodyTechnology } from '@graphql/types/server';

import * as Prismic from '@fe/components/Prismic';
import * as c from './components';
import * as Size from '@fe/components/Size';
import Carousel, { CarouselItem } from '@fe/views/common/Carousel';

export default class MakingDifference extends React.Component<{
  content: HomepageBodyTechnology,
}> {

  public render = () => {
    const {
      content: {
        fields,
        primary,
      },
    } = this.props;

    return (
      <c.Container>
        <c.Particles style={{left: 0, top: 0}} minX={0} maxX={0.2} minY={0} maxY={0.4} backgroundColor={`#50525D`} />
        <c.Particles style={{right: 0, bottom: 0}} minX={0.8} maxX={1} minY={0.6} maxY={1} backgroundColor={`#50525D`} />
        <c.Content>
          <c.Title>
            <Prismic.RichText asText>
              {primary!.section_heading}
            </Prismic.RichText>
          </c.Title>
          <Size.NotDesktop>
            <Carousel>
              {fields!.map((card) => (
                <CarouselItem key={Prismic.asText(card.card_title)}>
                  <c.Card.Container>
                    <c.Card.Title>
                      <Prismic.RichText asText>
                        {card.card_title}
                      </Prismic.RichText>
                    </c.Card.Title>
                    <c.Card.Body>
                      <Prismic.RichText asText>
                        {card.card_body}
                      </Prismic.RichText>
                    </c.Card.Body>
                  </c.Card.Container>
                </CarouselItem>
              ))}
            </Carousel>
          </Size.NotDesktop>
          <Size.Desktop>
            <c.Cards>
              {fields!.map((card) => (
                <c.Card.Container key={Prismic.asText(card.card_title)}>
                  <c.Card.Title>
                    <Prismic.RichText asText>
                      {card.card_title}
                    </Prismic.RichText>
                  </c.Card.Title>
                  <c.Card.Body>
                    <Prismic.RichText asText>
                      {card.card_body}
                    </Prismic.RichText>
                  </c.Card.Body>
                </c.Card.Container>
              ))}
            </c.Cards>
          </Size.Desktop>
        </c.Content>
      </c.Container>
    );
  }
}
