import * as React from 'react';
import LazyLoad from 'react-lazyload';
import MountNotifier from './mountNotifier';

/**
 * This component delays rendering of it's child.
 * Especially useful for components that are long running.
 * The component waits for the browser to become idle (if
 * the browser has this functionality) or for the user to
 * scroll to the component. This prevents it blocking on
 * load.
 *
 * We also recommend making the child a @loadable/component
 * in order to prevent the javascript being downloaded unnecessarily
 */
export default class ExpensiveComponent extends React.Component<{
  disableRenderOnIdle?: boolean,
  lazyConfig?: any,
}, {
  hasBeenIdle: boolean,
  isLazyLoadRendered: boolean,
}> {
  public static defaultProps = {
    disableRenderOnIdle: false,
  };

  public state = {
    hasBeenIdle: false,
    isLazyLoadRendered: false,
  };

  public componentDidMount = () => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => {
        this.setState({
          hasBeenIdle: true,
        });
      });
    }
  }

  public render = () => {
    const {
      children,
      disableRenderOnIdle,
      lazyConfig,
    } = this.props;
    const {
      hasBeenIdle,
      isLazyLoadRendered,
    } = this.state;

    const renderDueToIdle = !disableRenderOnIdle && hasBeenIdle;
    if (!isLazyLoadRendered && renderDueToIdle) {
      return children;
    } else {
      return (
        <LazyLoad key={`ll`} once scrollContainer={`body`} {...lazyConfig}>
          <MountNotifier
            onMount={() => this.setState({ isLazyLoadRendered: true })}
          >
            {children}
          </MountNotifier>
        </LazyLoad>
      );
    }
  }
}
