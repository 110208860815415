import * as React from 'react';

import Particle from '../particle';
import * as chance from 'chance';
import { putOnSphere } from '../geometry';
const random = chance();
import Canvas from '../common/canvas';
export default class ParticleAroundCircleComponent extends React.Component<{
  className?: string,
  CanvasContainer?: any,
  colors: Array<string>,
}> {

  private Canvas: Canvas;
  private circles: Array<Particle> = [];
  private centerCircle: Particle;

  private onUpdate = () => {
    this.circles.forEach((c) => c.update());
  }

  private getCoordinates = () => {
    const x = random.floating({ min: -1, max: 1 });
    const y = random.floating({ min: -1, max: 1 });
    const point = putOnSphere({ x, y }, 0.4);
    return {
      xProportion: point.x + 0.5,
      yProportion: point.y + 0.5,
    };
  }

  private onResize = (width, height) => {
    const size = Math.min(width, height);
    if (this.centerCircle) {
      this.centerCircle.reposition(size, size);
      this.centerCircle.radius = (size / 2) * 0.8;
    }
    this.circles.forEach((c) => c.reposition(size, size));
  }
  private onMouseMove = (x, y) => {
    this.circles.forEach((c) => c.onMouseMove(x, y));
  }
  private drawCircle = (context) => {
    const size = Math.min(this.Canvas.width, this.Canvas.height);
    const {
      xProportion,
      yProportion,
    } = this.getCoordinates();
    const radius = random.floating({ max: 15, min: 2 });
    const color = random.pickone(this.props.colors);
    const circle = new Particle({
      xProportion,
      yProportion,
      radius,
      color,
      width: size,
      height: size,
      c: context,
    });
    circle.draw();
    return circle;
  }

  public onMount = (canvas, _canvas, context) => {
    this.Canvas = canvas;
    // Density based on width
    const size = Math.min(this.Canvas.width, this.Canvas.height);
    this.centerCircle = new Particle({
      xProportion: 0.5,
      yProportion: 0.5,
      radius: (size / 2) * 0.8,
      color: `white`,
      width: size,
      height: size,
      c: context,
      isStationary: true,
    });
    for (let circleNo = 0; circleNo < 30; circleNo += 1) {
      const circle = this.drawCircle(context);
      this.circles.push(circle);
    }
    this.centerCircle.draw();
    this.circles.push(this.centerCircle);
    for (let circleNo = 0; circleNo < 10; circleNo += 1) {
      const circle = this.drawCircle(context);
      this.circles.push(circle);
    }
  }

  public render = () => {
    return (
      <Canvas
        onMount={this.onMount}
        onUpdate={this.onUpdate}
        onResize={this.onResize}
        onMouseMove={this.onMouseMove}
        className={this.props.className}
        CanvasContainer={this.props.CanvasContainer}
      />
    );
  }
}
