import styled from '@fe/styles';
import * as Section from '../components';
import CornerParticles from '../../Particles/views/cornerParticles';

import * as e from '@fe/components/Elements';

export const Container = styled(Section.Container)`
  background-color: #50525D;
  position: relative;
`;
export const Content = styled(e.Layout.Content)`
  ${(p) => p.theme.layout.marginResizeCss}
  position: relative;
  z-index: ${(p) => p.theme.depth.infront};
`;
export const Title = styled(e.Text.h1)`
  text-align: center;
  color: white;
  margin-bottom: 30px;
`;
export const Cards = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
  /* flex-direction: row;
  align-items: stretch;
  justify-content: center; */
`;
export const Card = {
  Container: styled.div`
    flex: 1;
    padding: 40px;
    min-width: 275px;
    background-color: #595B69;
    box-shadow: 0 2px 4px 2px rgba(59,61,69,0.15);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    text-align: left;
  `,
  Title: styled(e.Text.h4)`
    color: white;
  `,
  Body: styled(e.Text.PSmall)`
    color: white;
  `,
};
export const Particles = styled(CornerParticles)`
  position: absolute;
`;
