import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import linkResolver from './linkResolver';
import htmlSerialiser from './htmlSerialiser';

export default class RichTextComponent extends React.Component<{
  asText?: boolean,
  children: any,
}> {

  public render = () => {
    const {
      asText,
      children,
    } = this.props;
    return asText ? RichText.asText(children) : RichText.render(children, linkResolver, htmlSerialiser);
  }
}
