import * as React from 'react';
import { HomepageBodyImage_CutOut_Section } from '@graphql/types/server';
import { defaults } from '@fe/styles';
import * as Prismic from '@fe/components/Prismic';
import * as e from '@fe/components/Elements';
import * as c from './components';
import * as Section from '../components';
import { setParam } from '@fe/views/common/routeHandler';

export default class CutOutSection extends React.Component<{
  content: HomepageBodyImage_CutOut_Section,
}> {

  public render = () => {
    const {
      content: {
        primary,
        fields,
      },
    } = this.props;

    return (
      <Section.Container>
        <c.Container>
          <c.Columns.Container>
            <c.Columns.Column>
              <c.TitleContainer>
                <c.Title isColored>
                  <Prismic.RichText asText>
                    {primary!.section_heading}
                  </Prismic.RichText>
                </c.Title>
                <c.Title>
                  <Prismic.RichText asText>
                    {primary!.section_subheading}
                  </Prismic.RichText>
                </c.Title>
              </c.TitleContainer>
              <e.Button.Basic onClick={() => setParam(`auth`, `register`)}>
                {primary!.cta_text}
              </e.Button.Basic>
            </c.Columns.Column>
            <c.Columns.Column>
              <c.ImageContainer>
                <e.Images.Responsive
                  sizes={`100%`}
                  src={primary!.cutout_image_1!.url}
                />
              </c.ImageContainer>
            </c.Columns.Column>
          </c.Columns.Container>
          <c.Grid.Container>
            {fields!.map((column) => (
              <c.Grid.Section>
                <c.Grid.Title accent={defaults.color.green}>
                  <Prismic.RichText asText>
                    {column.column_title}
                  </Prismic.RichText>
                </c.Grid.Title>
                <c.Grid.Body>
                  <Prismic.RichText asText>
                    {column.column_body}
                  </Prismic.RichText>
                </c.Grid.Body>
              </c.Grid.Section>
            ))}
          </c.Grid.Container>
        </c.Container>
      </Section.Container>
    );
  }
}
