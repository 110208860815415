import * as React from 'react';
import data, { QueryProps } from './data';

// import * as e from '@fe/components/Elements';
import Hero from './Hero';
import Section from './section';

export class HomePage extends React.Component<QueryProps> {

  private get homepage() {
    return data.homepage;
  }

  public render = () => {
    return (
      <>
        <Hero content={this.homepage} />
        {this.homepage.body!.map((section) => (
          <Section key={`section-${section.type}`} content={section} />
        ))}
      </>
    );
  }
}

export default HomePage;
