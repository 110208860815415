import styled, { css } from '@fe/styles';
import SplineParticles from '../Particles/views/spline';
import * as e from '@fe/components/Elements';

export const Container = styled.div`
  min-height: 100%;
  min-height: 100vh;
  background: white;
`;
export const LaptopSection = styled.div`
  position: relative;
  top: 20px;
  min-width: 500px;
  ${(p) => p.theme.layout.paddingResizeCss}
`;
export const LaptopContainer = styled.div`
  margin: 0 auto;
  line-height: 0;
  max-width: 1400px;
  width: 100%;
  position: relative;
  z-index: ${(p) => p.theme.depth.infront};
  min-width: 400px;
`;
export const VideoContainer = styled.div`
  position: absolute;
  top: 6%;
  left: 12.5%;
  right: 12.5%;
`;
export const Video = styled.video`
  width: 100%;
`;
export const Laptop = styled(e.Images.Responsive)`
  pointer-events: none;
  width: 100%;
  position: relative;
`;
export const LaptopShadow = styled(e.Images.Responsive)`
  pointer-events: none;
  width: 100%;
  top: 100%;
  position: absolute;
  left: 0;
`;
export const BackgroundParticles = styled(SplineParticles)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const Controls = styled.div<{
  disabled?: boolean,
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${(p) => p.theme.depth.infront};
  opacity: 0;
  transition: ${(p) => p.theme.transition.default};
  :hover {
    opacity: 1;
    background-color: rgba(32,199,157, 0.7);
  }
  ${(p) => p.disabled && css`
    opacity: 0.3;
    pointer-events: none;
  `}
`;