import * as React from 'react';
import { HomepageBodyCommunity } from '@graphql/types/server';

import * as Prismic from '@fe/components/Prismic';
import * as e from '@fe/components/Elements';
import * as c from './components';
import * as Section from '../components';

export default class CarbonPositiveSection extends React.Component<{
  content: HomepageBodyCommunity,
}> {

  public render = () => {
    const {
      content: {
        primary,
      },
    } = this.props;

    return (
      <Section.Container>
        <c.Columns>
          <c.Column>
            <c.ColumnContent>
              <e.Layout.Content isLeft>
                <c.ImageContainer>
                  <c.Image
                    operation={`height`}
                    size={`200`}
                    {...Prismic.Image.getAttributes(primary!.left_image)}
                  />
                </c.ImageContainer>
                <e.Text.h3>
                  <Prismic.RichText asText>
                    {primary!.left_title}
                  </Prismic.RichText>
                </e.Text.h3>
                <e.Text.P>
                  <Prismic.RichText asText>
                    {primary!.left_message}
                  </Prismic.RichText>
                </e.Text.P>
              </e.Layout.Content>
            </c.ColumnContent>
          </c.Column>
          <c.Column>
            <c.ColumnContent>
              <e.Layout.Content isLeft>
                <c.ImageContainer>
                  <c.Image
                    operation={`height`}
                    size={`200`}
                    {...Prismic.Image.getAttributes(primary!.right_image)}
                  />
                </c.ImageContainer>
                <e.Text.h3>
                  <Prismic.RichText asText>
                    {primary!.right_title}
                  </Prismic.RichText>
                </e.Text.h3>
                <e.Text.P>
                  <Prismic.RichText asText>
                    {primary!.right_message}
                  </Prismic.RichText>
                </e.Text.P>
              </e.Layout.Content>

            </c.ColumnContent>
          </c.Column>
        </c.Columns>
      </Section.Container>
    );
  }
}
