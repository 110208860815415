import * as React from 'react';
import Carousel from '@fe/components/Carousel';
import styled from '@fe/styles';

export const CarouselItem = styled.div`
  position: relative;
  left: 15%;
  height: 100%;
  ${(p) => p.theme.layout.paddingResizeCss}
`;
const StyledCarousel = styled(Carousel)`
  /* Make slides 100% height */
  .slick-track.slick-track {
    display: flex;
    .slick-slide {
      height: initial;
      & > * {
        height: 100%;
      }
    }
  }
  .slick-slide.slick-slide {
    opacity: 0.4;
    transition: ${(p) => p.theme.transition.slow};
    &.slick-current {
      opacity: 1;
    }
  }
  .slick-dots.slick-dots {
    text-align: left;
    position: relative;
    bottom: 0;
    li {
      margin-left: 0;
      button:before {
        opacity: 1;
        color: #5B5E6D;
        transition: ${(p) => p.theme.transition.slow};
      }
    }
    li.slick-active {
      button:before {
        color: #1EC191;
      }
    }
  }
`;

export default class CarouselComponent extends React.Component {

  public render = () => {
    return (
      <StyledCarousel
        slidesToShow={1.2}
        slidesToScroll={1}
        autoplay={false}
        arrows={false}
        dots={true}
        // onInit={onInit}
        {...this.props}
      />
    );
  }
}
